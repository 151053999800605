import type { Level, Transport } from './types'


interface TransportOptions {
  level?: Level
  parse?: boolean
  close?: () => Promise<void>
}

interface BuildTransport {
  (fn: (source: string) => void, options?: TransportOptions & { parse?: false | undefined }): Transport
  (fn: (source: Record<string, any>) => void, options?: TransportOptions & { parse: true }): Transport
}

export const buildTransport: BuildTransport = (fn: (source: any) => void, options: TransportOptions = {}) => {
  const { parse, close, level = 'trace' } = options

  return {
    level,
    write: (json: string) => {
      try {
        const source = parse ? JSON.parse(json) : json
        fn(source)
      }
      catch (error) {
        console.error(error)
      }
    },
    close: async () => {
      if (typeof close === 'function') {
        await close()
      }
    },
  }
}
