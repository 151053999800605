import React from 'react'
import { useSearchParams } from 'router'

import GiftWithSpendThresholdStaticNotification from 'compositions/staticNotifications/GiftWithSpendThresholdStaticNotification/GiftWithSpendThresholdStaticNotification'


const StaticNotifications: React.FC = () => {
  const [ { autoTestSkip } ] = useSearchParams()

  if (autoTestSkip) {
    return null
  }

  return (
    <>
      <GiftWithSpendThresholdStaticNotification />
    </>
  )
}

export default StaticNotifications
