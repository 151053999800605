import React from 'react'
import { twcx } from 'helpers'

import { Notification, type NotificationProps } from 'components/feedback'
import { Text } from 'components/dataDisplay'

import ProductImage from 'compositions/ProductImage/ProductImage'


export type ImageWithTextNotificationProps = NotificationProps & {
  image: string
  imageClassName?: string
  text: Intl.Message | string
  textStyle: 'sh6' | 'p2'
  staticMode?: boolean
}

const ImageWithTextNotification: React.FC<ImageWithTextNotificationProps> = (props) => {
  const { closeNotification, image, imageClassName, text, textStyle, onClose, staticMode } = props

  return (
    <Notification
      className="bg-light-beige"
      closeNotification={closeNotification}
      timeout={staticMode ? null : undefined}
      withAnimation={!staticMode}
      onClose={onClose}
    >
      <div className="flex">
        <ProductImage
          className={twcx('-my-8 -ml-16 mr-16 size-80 flex-none self-center', imageClassName)}
          bgColor="light-beige"
          src={image}
          remWidth={80}
        />
        <div className="flex items-center">
          <Text
            message={text}
            style={textStyle}
            html
          />
        </div>
      </div>
    </Notification>
  )
}

export default React.memo(ImageWithTextNotification)
