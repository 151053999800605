import React from 'react'
import links from 'links'
import { Redirect } from 'router'
import { useSubscription } from 'modules/subscription'

import PageSkeleton from 'compositions/PageSkeleton/PageSkeleton'


const HasNeverSubscribed: React.CFC = ({ children }) => {
  const { subscription, isFetching } = useSubscription()

  if (isFetching) {
    return (
      <PageSkeleton />
    )
  }

  if (!subscription.hasNeverSubscribed) {
    return (
      <Redirect to={links.subscription.main} />
    )
  }

  return (
    <>{children}</>
  )
}


export default HasNeverSubscribed
