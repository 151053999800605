import { type ApolloClients } from 'apollo-client'
import { constants } from 'helpers'

import initialDataQuery from './initialData.graphql'


const dumpData = (apolloClient: ApolloClients['default']) => {
  try {
    // to log only frontend relative items
    const feAbTests: string[] = Object.values(constants.abTests)
    const feFeatures: string[] = Object.values(constants.features)

    const data = apolloClient.cache.readQuery({
      query: initialDataQuery,
    })

    const activeAbTests = data.abTests.data.abTests.reduce((result, item) => {
      const { enabled, value, name } = item

      if ((enabled || value !== 'a') && feAbTests.includes(name)) {
        result.push(`${name}(${value})`)
      }

      return result
    }, []).sort()

    const activeFeatures = data.features.data.featureToggles.reduce((result, item) => {
      const { enabled, name } = item

      if (enabled && feFeatures.includes(name)) {
        result.push(name)
      }

      return result
    }, []).sort()

    return {
      activeAbTests,
      activeFeatures,
      currentUser: data.currentUser,
    }
  }
  catch (error) {
    return null
  }
}


export default dumpData
