import React, { useMemo, useState } from 'react'
import { IntlProvider } from 'intl'
import cookieStorage from 'cookie-storage'
import { constants } from 'helpers'

import { LocalePreferencesContext } from './LocalePreferencesContext'


type LocalePreferencesProviderProps = {
  children: React.ReactNode
  value: Intl.LocalePreferences
  onChange?: (config: Intl.LocalePreferences) => void
}

const LocalePreferencesProvider: React.FC<LocalePreferencesProviderProps> = (props) => {
  const { children, value: initialValue, onChange } = props

  const [ value, setValue ] = useState(initialValue)

  const contextValue = useMemo<Intl.LocalePreferencesContextValue>(() => {
    return {
      localePreferences: value,
      setLocalePreferences: (updater) => {
        const newValue = updater(value)

        // update different storages
        window.__LOCALE_PREFERENCES__ = newValue
        cookieStorage.setItem(constants.cookieNames.localeOverride, newValue.locale)

        setValue(newValue)

        if (typeof onChange === 'function') {
          onChange(newValue)
        }
      },
    }
  }, [ value, onChange ])

  return (
    <LocalePreferencesContext.Provider value={contextValue}>
      <IntlProvider locale={value.locale} currency={value.currency}>
        {children}
      </IntlProvider>
    </LocalePreferencesContext.Provider>
  )
}

export default LocalePreferencesProvider
