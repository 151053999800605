import type { Level } from './types'


export const levels: Record<Level, number> = {
  fatal: 0,
  error: 1,
  warn: 2,
  info: 3,
  debug: 4,
  trace: 5,
}

export const isLevelEnabled = (entryLevel: Level, baseLevel: Level) => {
  return levels[entryLevel] <= levels[baseLevel]
}

export const asString = (value: any): string => {
  try {
    return JSON.stringify(value)
  }
  catch (error) {
    return '[Circular]'
  }
}

export const composeError = (obj: any) => {
  const error = new Error(obj.message)
  error.stack = obj.stack
  error.name = obj.name || error.name

  if (obj.cause) {
    error.cause = composeError(obj.cause)
  }

  return error
}

