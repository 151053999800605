import React, { Component } from 'react'
import logger from 'logger'
import { track } from 'analytics'
import FraudModal from 'compositions/modals/FraudModal/FraudModal'
import Page500 from 'pages/Page500/Page500'


class ErrorBoundary extends Component<{ children?: React.ReactNode }, { error?: Error | null, sentryEventId?: string, fraud?: boolean }> {

  state = {
    error: null,
    sentryEventId: null,
    fraud: false,
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // @ts-ignore
    if (error?.networkError?.statusCode === 451) {
      this.setState({
        fraud: true,
      })
      return
    }

    logger.error({
      ...errorInfo,
      error,
    })

    track('Global error')

    this.setState({
      error,
    })
  }

  render() {
    const { error, fraud } = this.state

    if (fraud) {
      return (
        <div className="flex min-h-full flex-col">
          <main id="main" className="flex-auto">
            <FraudModal />
          </main>
        </div>
      )
    }

    if (error) {
      return (
        <Page500 error={error} />
      )
    }

    return (
      <>{this.props.children}</>
    )
  }
}


export default ErrorBoundary
