import { constants } from 'helpers'
import { useAb, useOnce } from 'hooks'
import localStorage from 'local-storage'

import type { ModifiedUser } from 'modules/user'


export const useCnstrcTrackUserData = (user: ModifiedUser) => {
  const constructorRecommendationsAbTestValue = useAb(constants.abTests.constructorRecommendations) || 'a'

  useOnce(() => {
    if (!__CLIENT__) {
      return
    }

    window.cnstrcUserId = user?.id ? String(user.id) : undefined
  }, [ user?.id ])

  useOnce(() => {
    if (!__CLIENT__) {
      return
    }

    const localStorageValue = localStorage.getItem(constants.localStorageNames.constructorRecommendations)

    if (localStorageValue !== constructorRecommendationsAbTestValue) {
      localStorage.setItem(constants.localStorageNames.constructorRecommendations, constructorRecommendationsAbTestValue)
    }
  }, [ constructorRecommendationsAbTestValue ])
}
