import type { FormatOptions } from './types'


function tryStringify(o: unknown): string {
  try {
    return JSON.stringify(o)
  }
  catch (e) {
    return '"[Circular]"'
  }
}

// fast formatter to process messages with arguments
export const format = (message: any, args: any[], options?: FormatOptions) => {
  const sf = options?.stringify || tryStringify

  if (!message) {
    return message
  }

  if (typeof message !== 'string') {
    return sf(message)
  }

  let argIndex = 0
  const argLength = args.length

  const length = message.length
  let result = ''

  for (let i = 0; i < length; i++) {
    const char = message.charAt(i)

    if (char !== '%' || i === length - 1) {
      result += char
      continue
    }

    const tCharCode = message.charCodeAt(i + 1)
    let replaced = false // if it isn't replaced we need to keep control characters
    // mo to skip type char
    i++

    // if not enough arguments, just exit
    if (argIndex < argLength) {
      const arg = args[argIndex]

      switch (tCharCode) {
        case 100: // 'd'
        case 102: // 'f'
          if (arg !== null && arg !== undefined) {
            result += Number(arg)
            replaced = true
          }
          break
        case 105: // 'i'
          if (arg !== null && arg !== undefined) {
            result += Math.floor(arg)
            replaced = true
          }
          break
        case 79: // 'O'
        case 111: // 'o'
        case 106: // 'j'
          if (arg === undefined) {
            break
          }

          const type = typeof arg

          if (type === 'string') {
            result += `'${arg}'`
          }
          else if (type === 'function') {
            result += arg.name || '<anonymous>'
          }
          else {
            result += sf(arg)
          }

          replaced = true
          break
        case 115: // 's'
          result += String(arg)
          replaced = true
          break
      }

      if (tCharCode === 37) {
        // % is a special case when we shouldn't move arg index
        result += char
        replaced = true
      }
      else {
        argIndex++
      }
    }

    // if the chars haven't been replaced, append them to the result
    if (!replaced) {
      result += `${char}${String.fromCharCode(tCharCode)}`
    }
  }

  return result
}
