import { useQuery } from 'apollo-client'

import { useFt } from 'hooks'
import { constants } from 'helpers'
import productGiftPromoQuery from './graph/productGiftPromo.graphql'


type UseProductGiftPromoParams = {
  offer?: 'GIFT_FOR_SUBTOTAL' | 'GIFT_FOR_BUY_QUEUE'
}

export const useProductGiftPromo = (params?: UseProductGiftPromoParams) => {
  const { offer = 'GIFT_FOR_SUBTOTAL' } = params || {}

  const isGwpSpendThresholdEnabled = useFt(constants.features.gwpSpendThreshold)
  const isGwpBuyYourQueueEnabled = useFt(constants.features.gwpBuyYourQueue)
  const isCommerceToolsGWPEnabled = useFt(constants.features.commerceToolsGWP)

  const skip = offer === 'GIFT_FOR_SUBTOTAL' ? (
    !isGwpSpendThresholdEnabled && !isCommerceToolsGWPEnabled
  ) : (
    !isGwpBuyYourQueueEnabled && !isCommerceToolsGWPEnabled
  )

  const { data, isFetching } = useQuery(productGiftPromoQuery, {
    variables: {
      input: {
        offer,
      },
    },
    skip,
    context: {
      ssrCache: true,
    },
  })

  return {
    subTotal: data?.productGiftPromo?.data?.condition?.subTotal ?? null,
    queueItemsCount: data?.productGiftPromo?.data?.condition?.queueItemsCount ?? null,
    giftProduct: data?.productGiftPromo?.data?.giftProduct || null,
    isFetching,
  }
}

export default useProductGiftPromo
