import React from 'react'

import { AspectContainer } from 'components/layout'
import { Icon, Image, type ImageProps } from 'components/dataDisplay'


export const bgColors = {
  'white': 'fff',
  'light-beige': 'f7efe9',
  'gray-10': 'fafafa',
  'gray-20': 'f0f0f0',
  'gray-30': 'dadada',
}

export type BgColor = keyof typeof bgColors | string

export type ProductImageProps = Pick<ImageProps, 'src' | 'width' | 'sizes' | 'remWidth' | 'lazy' | 'quality'> & {
  className?: string
  bgColor?: BgColor
  alt?: string
  fit?: 'cover' | 'contain'
  lazy?: boolean
}

const ProductImage: React.FunctionComponent<ProductImageProps> = (props) => {
  const { className, src, alt, bgColor = 'white', fit = 'contain', sizes, width, remWidth, quality, lazy = true } = props

  if (!src) {
    return (
      <AspectContainer className={className} fit={fit}>
        <Icon name="other/product" />
      </AspectContainer>
    )
  }

  return (
    <AspectContainer className={className} fit={fit}>
      <Image
        src={src}
        alt={alt}
        bgColor={bgColors[bgColor]}
        sizes={sizes}
        width={width}
        quality={quality}
        remWidth={remWidth}
        lazy={lazy}
      />
    </AspectContainer>
  )
}


export default ProductImage
