import type { Level, LoggerInterface, LoggerOptions, LogFn, MixinFn, Transport } from './types'
import { mapHttpRequest, mapHttpResponse, mapError } from './serializers'
import { format } from './formatter'
import { isLevelEnabled, asString } from './util'
import { SerializerFn } from './types'


export class Logger implements LoggerInterface {
  private _level: Level
  private _mixin: MixinFn
  private _serializers: Record<string, SerializerFn>
  private _transports: Transport[] = []
  private _bindings: string = ''

  private _messageKey = 'message'
  private _errorKey = 'error'

  constructor(options: LoggerOptions = {}) {
    this._level = options.level || 'trace'
    this._mixin = options.mixin
    this._serializers = {
      [this._errorKey]: mapError,
      ...options.serializers,
    }
    this._transports = options.transports || []
  }

  public async close() {
    if (this._transports?.length > 0) {
      await Promise.allSettled(
        this._transports.map((t) => t.close())
      )
    }
  }

  public child(bindings: Record<string, unknown>, options: Omit<LoggerOptions, 'transports'> = {}): LoggerInterface {
    // override serializers
    const serializers = Object.assign({}, this._serializers, options.serializers)

    const composedOptions: LoggerOptions = {
      level: options.level || this._level,
      mixin: options.mixin || this._mixin || undefined,
      serializers,
      // we don't support transport changing for child logger
      transports: this._transports,
    }

    const child = new Logger(composedOptions)
    // we keep bindings as a string for performance
    child._bindings = child.asJsonPart(bindings)
    return child
  }

  public get level() {
    return this._level
  }

  public set level(value: Level) {
    this._level = value || 'trace'
  }

  public bindings() {
    return JSON.parse(`{${this._bindings || ''}`)
  }

  public fatal = this.createLogFn('fatal')
  public error = this.createLogFn('error')
  public warn = this.createLogFn('warn')
  public info = this.createLogFn('info')
  public debug = this.createLogFn('debug')
  public trace = this.createLogFn('trace')

  private createLogFn(level: Level): LogFn {
    return (o: any, ...n: any[]) => {
      if (typeof o === 'object') {
        let msg = o

        if (o !== null) {
          if (o.method && o.headers && o.socket) {
            // http request
            o = mapHttpRequest(o)
          }
          else if (typeof o.setHeader === 'function') {
            o = mapHttpResponse(o)
          }
        }

        let formatParams: any[]
        if (msg === null && n.length === 0) {
          formatParams = [ null ]
        }
        else {
          msg = n.shift()
          formatParams = n
        }

        this.write(o, format(msg, formatParams, this.formatOptions), level)
      }
      else {
        let msg = o === undefined ? n.shift() : o
        this.write(null, format(msg, n, this.formatOptions), level)
      }
    }
  }

  private get formatOptions() {
    // TODO add redactor here - added on 2023-11-20 by maddoger
    return undefined
  }

  private write(_obj: Record<string, unknown>, msg: string, level: Level) {
    // go out before all computations, probably we don't need it here
    if (!isLevelEnabled(level, this._level)) {
      return
    }

    const messageField = this._messageKey
    const errorField = this._errorKey

    let obj: any

    // fill obj and message
    if (_obj === undefined || _obj === null) {
      obj = {}
    }
    else if (_obj instanceof Error) {
      obj = { [errorField]: _obj }
      if (msg === undefined) {
        msg = _obj.message
      }
    }
    else {
      obj = _obj
      // copy message from the inside error field
      if (msg === undefined && !obj[messageField] && obj[errorField]) {
        msg = obj[errorField].message
      }
    }

    // add mixin
    if (this._mixin) {
      Object.assign(obj, this._mixin(obj, level, this))
    }

    // convert to string with redaction and others
    const str = this.asJson(obj, msg, level, new Date().toISOString())

    for (const t of this._transports) {
      try {
        if (isLevelEnabled(level, t.level)) {
          t.write(str)
        }
      }
      finally {}
    }
  }

  private asJsonPart(obj: Record<string, unknown>) {
    const serializers = this._serializers
    // TODO add stringifiers if we need redaction - added on 2023-11-22 by maddoger
    // const stringifiers = {}

    let value: any
    let propString = ''

    // convert all obj properties to JSON
    for (const key in obj) {
      value = obj[key]

      if (Object.prototype.hasOwnProperty.call(obj, key) && value !== undefined) {
        if (serializers[key]) {
          value = serializers[key](value)
        }

        const tValue = typeof value

        if (tValue === 'function' || tValue === 'undefined') {
          continue
        }

        if (tValue === 'number' || tValue === 'boolean') {
          if (tValue === 'number' && Number.isFinite(value) === false) {
            value = null
          }
        }
        else {
          value = asString(value)
        }

        if (value === undefined) {
          continue
        }

        const strKey = asString(key)
        propString += ',' + strKey + ':' + value
      }
    }

    return propString
  }

  /**
   * Generates JSON string from log event info, applying serializers, stringifiers to redact data
   */
  private asJson(obj: Record<string, unknown>, msg: string, level: Level, time: string): string {
    const start = `"level":${asString(level)},"time":${asString(time)}`
    const bindings = this._bindings
    const propString = this.asJsonPart(obj)
    const msgString = this.asJsonPart({ [this._messageKey]: msg })

    // Compose JSON string in specific order
    return `{${start}${bindings}${msgString}${propString}}\n`
  }
}
