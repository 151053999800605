import React, { useEffect, useMemo, useRef } from 'react'
import links from 'links'
import localStorage from 'local-storage'
import { closeNotification, openNotification } from 'notifications'
import { usePathname } from 'router'
import { useIntl } from 'intl'
import { useFt } from 'hooks'

import { constants } from 'helpers'

import { useDevice } from 'device'
import { useProductGiftPromo } from 'modules/products'

import mothersDayImage from './images/mothers-day-notification.png'
import fathersDayImage from './images/fathers-day-notification.png'
import anniversaryGWPMobile from './images/anniversary-gwp-mobile.png'
import anniversaryGWPDesktop from './images/anniversary-gwp-desktop.png'

import messages from './messages'


const applicablePagePaths = [
  links.giftSets.colognes,
  links.giftSets.perfumes,
  links.shop.accessories,
  links.shop.bathBody,
  links.shop.candlesHomeScents,
  links.shop.colognes,
  links.shop.perfumes,
  links.shop.scentbirdCases,
  links.shop.theEdit,
  links.subscription.colognes,
  links.subscription.main,
  links.subscription.perfumes,
]
  .map((link) => link.replace('(.*)', '').replace(':slug', ''))
  .join('|')

const applicablePagePathRegExp = new RegExp(`^(${applicablePagePaths})`)

const handleNotificationClose = () => {
  localStorage.setItem(constants.localStorageNames.giftWithSpendThresholdShown, true)
}

const GiftWithSpendThresholdStaticNotification: React.FC = () => {
  const notificationIdRef = useRef<number>(null)

  const path = usePathname()

  const intl = useIntl()

  const { isMobile } = useDevice()

  const { subTotal, giftProduct } = useProductGiftPromo()
  const isHolidayMothersDayEnabled = useFt(constants.features.holidayMothersDay)
  const isHolidayFathersDayEnabled = useFt(constants.features.holidayFathersDay)

  const isApplicablePath = useMemo(() => {
    return applicablePagePathRegExp.test(path)
  }, [ path ])

  useEffect(() => {
    const notificationWasShown = localStorage.getItem(constants.localStorageNames.giftWithSpendThresholdShown)

    if (notificationWasShown || !subTotal) {
      return
    }

    if (!isApplicablePath) {
      if (notificationIdRef.current) {
        closeNotification(notificationIdRef.current)
        notificationIdRef.current = null
      }

      return
    }

    if (notificationIdRef.current) {
      return
    }

    const price = intl.formatPrice(subTotal, { removeZeroCents: true })
    const promoName = giftProduct?.promo?.productInfo?.name
    const promoImage = giftProduct?.promo?.image

    // TODO: Move this logic to the Strapi — added on 28–02–2024 by algeas
    if (isHolidayMothersDayEnabled) {
      notificationIdRef.current = openNotification('imageWithText', {
        text: {
          ...messages.mothersDay,
          values: {
            price,
          },
        },
        textStyle: 'p2',
        image: mothersDayImage,
        imageClassName: '-my-16 -ml-24 w-[98rem] h-[112rem]',
        onClose: handleNotificationClose,
      })
    }
    if (isHolidayFathersDayEnabled) {
      notificationIdRef.current = openNotification('imageWithText', {
        text: {
          ...messages.fathersDay,
          values: {
            price,
          },
        },
        textStyle: 'p2',
        image: fathersDayImage,
        imageClassName: '-my-16 -ml-24 w-[98rem] h-[112rem]',
        onClose: handleNotificationClose,
      })
    }
    else {
      notificationIdRef.current = openNotification('imageWithText', {
        image: promoImage,
        text: {
          ...messages.offer,
          values: {
            promoName,
            price,
          },
        },
        textStyle: 'sh6',
        staticMode: true,
        onClose: handleNotificationClose,
      })
    }
  }, [ isApplicablePath, subTotal, isMobile, intl, isHolidayMothersDayEnabled, isHolidayFathersDayEnabled ])

  return null
}

export default GiftWithSpendThresholdStaticNotification
